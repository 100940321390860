<template>
    <div
        ref="transcriptionBlockRef"
        :class="{'is--focused': isFocusedInput}"
        class="call-history-transcription"
    >
        <div class="call-history-transcription__header block-title">
            <span class="transcription-title">
                {{ t('callHistoryView.ai.transcriptionHeader') }}
            </span>
            <div class="transcription-actions flex-center gap-2">
                <SearchInputField
                    v-model="searchModel"
                    @focus="onInputFocus"
                    @blur="onInputBlur"
                />
                <div class="transcription-actions__add flex-center gap-x-2">
                    <div
                        v-if="hasPrevNextActions"
                        class="flex items-center gap-2"
                    >
                        <span class="inline-flex items-center gap-x-1 text-sm">
                            <span class="text-active-elements">{{ selectedIndex + 1 }}</span>
                            <span class="text-placeholders uppercase">{{ t('callHistoryView.of') }}</span>
                            <span class="text-default-text">{{ selectedMessages.length }}</span>
                        </span>
                        <div class="inline-flex items-center gap-x-1">
                            <VcButtonIcon
                                small
                                color="primary-alternative"
                                type="outline"
                                class="!p-1"
                                icon="vc-lc-chevron-up"
                                :disabled="isPrevDisabled"
                                @click="prevMessage"
                            />
                            <VcButtonIcon
                                small
                                color="primary-alternative"
                                type="outline"
                                class="!p-1"
                                icon="vc-lc-chevron-down"
                                :disabled="isNextDisabled"
                                @click="nextMessage"
                            />
                        </div>
                    </div>
                    <VcPopover
                        :teleported="isTeleported"
                        trigger="hover"
                        placement="top"
                    >
                        <template #reference>
                            <BlockAction
                                :icon="autoScroll ? 'vc-lc-mouse' : 'vc-lc-mouse-off'"
                                @click="autoScroll = !autoScroll"
                            />
                        </template>
                        <div class="p-3 text-center">
                            <div v-if="!autoScroll">
                                {{ t('callHistoryView.ai.autoscrollDisabled') }}
                                <br>
                                {{ t('callHistoryView.ai.clickToEnable') }}
                            </div>
                            <div v-else>
                                {{ t('callHistoryView.ai.autoscrollEnabled') }}
                                <br>
                                {{ t('callHistoryView.ai.clickToDisable') }}
                            </div>
                        </div>
                    </VcPopover>
                    <BlockAction
                        v-if="!isFocusedInput && hasScrollTop"
                        icon="vc-lc-arrow-up-to-line"
                        @click="scrollToTop"
                    />
                </div>
            </div>
        </div>
        <div
            class="min-h-20 transcript-messages h-full relative"
        >
            <ul
                ref="messageContainer"
                class="transcript-messages__content overflow-y-auto max-h-full min-h-20 py-5 lg:py-10 px-3 lg:px-6 flex flex-col gap-4 scroll-smooth"
                @scroll="onScroll"
                @scrollend="onScrollEnd"
            >
                <li
                    v-for="(message, index) in transcriptMessagesWithEmotions"
                    :key="`mess-${index}`"
                    :data-start="message.startTime"
                    class="relative flex flex-col w-full"
                >
                    <TranscriptionMessage
                        :message="message"
                        :is-reverse="message.speaker === CALLER_SPEAKER"
                        :active-message="selectedMessage"
                        :search-data="debouncedSearchModel"
                        @click:message="onClickMessage(message)"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import TranscriptionMessage from '@/components/Common/TranscriptionMessage.vue'
import { TMessageItem } from '@/types/types'
import { computed, nextTick, ref, watch } from 'vue'
import BlockAction from '@/components/Common/BlockAction.vue'
import SearchInputField from '@/components/Common/SearchInputField.vue'
import { refDebounced } from '@vueuse/core'
import { getMessageByTime, transcriptMessagesWithEmotions } from '@/composables/useAiTabData.ts'
import { CALLER_SPEAKER } from '@/enums/aiTab.enum.ts'
import { useI18n } from 'vue-i18n'
import { isTeleported, isWidgetState, usedWidgetShadowRootEl } from '@/services/GlobalState.ts'
import { getCurrentPlayerTime, setSoundPlayerTime } from '@/composables/useCallRecording.ts'

const { t } = useI18n()

/* Data */
const isFocusedInput = ref<boolean>(false)
const messageContainer = ref<HTMLElement>()
const transcriptionBlockRef = ref<HTMLElement>()
const searchModel = ref<string>('')
const debouncedSearchModel = refDebounced(searchModel, 500)
const selectedIndex = ref<number>(0)
const selectedMessages = ref<Array<TMessageItem>>([])
const autoScroll = ref<boolean>(true)
const lastScrolledMessage = ref<TMessageItem | undefined>(undefined)
const isProgrammaticScroll = ref<boolean>(false)
const hasScrollTop = ref<boolean>(false)

/* Computed */
const selectedMessage = computed(() => {
    if (!selectedMessages.value.length) {
        return undefined
    }
    return selectedMessages.value[selectedIndex.value]?.sentence_id
})
const isNextDisabled = computed(() => {
    return selectedIndex.value >= selectedMessages.value.length - 1
})
const isPrevDisabled = computed(() => {
    return selectedIndex.value <= 0
})
const hasPrevNextActions = computed(() => {
    return selectedMessages.value.length > 1
})

/* Methods */
function onScrollEnd () {
    isProgrammaticScroll.value = false
}
function onScroll (event: Event) {
    if (event.target instanceof HTMLElement) {
        hasScrollTop.value = event.target.scrollTop > 0
    }
    if (!isProgrammaticScroll.value) {
        autoScroll.value = false
    }
}
function onInputFocus () {
    isFocusedInput.value = true
    autoScroll.value = false
}
function onInputBlur () {
    !searchModel.value && (isFocusedInput.value = false)
}
function onClickMessage (message: TMessageItem) {
    setSoundPlayerTime(message.startTime)
}
function scrollToMessage (time: number) {
    const _container = messageContainer.value
    if (!_container) return
    if (time === 0) {
        isProgrammaticScroll.value = true
        _container.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
        return
    }
    const _message = getMessageByTime(time)

    if (_message && lastScrolledMessage.value?.sentence_id !== _message.sentence_id) {
        lastScrolledMessage.value = _message

        scroll(_message)
    }
}
function getMessageBlockElement (startTime: number) {
    if (isWidgetState.value && usedWidgetShadowRootEl.value) {
        return usedWidgetShadowRootEl.value.querySelector(`[data-start="${startTime}"]`) as HTMLElement
    }
    return document.querySelector(`[data-start="${startTime}"]`) as HTMLElement
}
function scroll (message: TMessageItem) {
    const _container = messageContainer.value
    if (message) {
        const _messageBlock = getMessageBlockElement(message.startTime)

        if (!_messageBlock) return

        const _messageBlockBound = _messageBlock.getBoundingClientRect()
        // const _top = Math.max(_messageBlock.offsetTop - _container.offsetTop - _container.getBoundingClientRect().height + _messageBlockBound.height + 6, 0)
        const _top = Math.max(_messageBlock.offsetTop - (_container?.offsetTop || 0) - _messageBlockBound.height + 6, 0)

        isProgrammaticScroll.value = true
        _container?.scrollTo({
            top: _top,
            left: 0,
            behavior: 'smooth'
        })
    }
}

function scrollToTop () {
    scrollToMessage(0)
}
function nextMessage () {
    const nextIndex = selectedIndex.value + 1
    selectedIndex.value = Math.min(nextIndex, selectedMessages.value.length - 1)
    nextTick(() => {
        const _message = selectedMessages.value[selectedIndex.value]
        scroll(_message)
    })
}
function prevMessage () {
    const prevIndex = selectedIndex.value - 1
    selectedIndex.value = Math.max(prevIndex - 1,0)
    nextTick(() => {
        const _message = selectedMessages.value[selectedIndex.value]
        scroll(_message)
    })
}
function scrollToMessageByText (text: string) {
    selectedIndex.value = 0
    if (!text) {
        selectedMessages.value = []
        return
    }

    selectedMessages.value = transcriptMessagesWithEmotions.value.filter(item => {
        return item.text.toLocaleLowerCase().includes(debouncedSearchModel.value.toLocaleLowerCase().trim())
    })

    nextTick(() => {
        transcriptionBlockRef.value?.scrollIntoView({
            behavior: 'smooth'
        })
        if (selectedMessages.value.length) {
            const _message = selectedMessages.value[0]
            scroll(_message)
        } else {
            scrollToMessage(0)
        }
    })
}
function setSearchData (data: string) {
    searchModel.value = data
    onInputFocus()
}

watch(
    getCurrentPlayerTime,
    (time: number) => {
        if (autoScroll.value) {
            scrollToMessage(time)
        }
    }
)

defineExpose({
    setSearchData
})

watch(
    debouncedSearchModel,
    (val) => {
        scrollToMessageByText(val)
    }
)
</script>

<style lang="scss">
    .call-history-transcription {
        @apply flex flex-col h-full overflow-hidden pb-1;
        transition: var(--base-transition);
        scroll-margin-top: 24px;
        .transcription-actions {
            transition: var(--base-transition);
        }
        .transcript-messages__content {
            transition: var(--base-transition);
            scroll-behavior: smooth;
        }
        &__header {
            box-shadow: 0 4px 8px 0 rgba(38, 49, 72, 0.08);
            @apply py-3 flex justify-between items-center px-4 border-b border-ui-lines relative bg-light-bg;
        }
        &__content {
            @apply max-w-full;
        }
        &.is--focused {
            .transcription-title {
                display: none;
            }
            .transcription-actions {
                @apply justify-between w-full gap-x-2;
            }
        }

        .transcription-actions__add:empty {
            display: none;
        }
    }
</style>
