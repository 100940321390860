import { computed, Ref, ref } from 'vue'
import { refDebounced } from '@vueuse/core'
import Decryptor from '@voicenter-team/decryptor-js'
import useNotifyService from '@/composables/useNotifyService.ts'
import { CallAiOnlyHistoryData, CallHistoryData } from '@/types/api'
import { hasRecordDecryptionData, recordDecryptionData } from '@/composables/useEncryptedData.ts'
import { calcRecordingDuration } from '@/helpers/apiData.helper.ts'
import { ComponentExposed } from 'vue-component-type-helpers'
import { VcSoundPlayerWrapper } from '@voicenter-team/voicenter-ui-plus'

export type SoundPlayerWrapperRef = Ref<ComponentExposed<typeof VcSoundPlayerWrapper> | undefined> | undefined

const decryptor = new Decryptor()

const STATUS_FORBIDDEN = 403

/* Data */
export const soundData = ref<string>('')
export const recordingDuration = ref<number>(0)
export const soundSource = ref<string>('')
const currentPlayerTime = ref(0)
export const currentPlayerTimeDebounced = refDebounced(currentPlayerTime, 500, { maxWait: 1000 })
const isPlaying = ref<boolean>(false)
let soundPlayerWrapperRef: SoundPlayerWrapperRef = undefined

/* Computed */
export const getCurrentPlayerTime = computed(() => {
    return currentPlayerTime.value
})
export const isRecording = computed(() => {
    return !!soundSource.value
})
export const isSoundPlayerPlaying = computed(() => {
    return isPlaying.value
})

/* Methods */
export function onSoundPlayerWrapperInitialized (player: SoundPlayerWrapperRef) {
    soundPlayerWrapperRef = player
}
export function clearSoundPlayerWrapperRef () {
    soundPlayerWrapperRef = undefined
}
export function onSoundPlayerStop () {
    isPlaying.value = false
}
export function onSoundPlayerStart () {
    isPlaying.value = true

    if (!soundData.value) {
        fetchSoundData()
    }
}
export function setSoundPlayerTime (time: number, ignorePlaying = false) {
    if (!soundData.value) {
        return
    }
    if (ignorePlaying || !isPlaying.value) {
        soundPlayerWrapperRef?.value?.setTime(time)
    }
}
export function onCurrentPlayerTimeUpdate (time: number) {
    currentPlayerTime.value = time
}
export function setRecordingData (data: CallHistoryData | CallAiOnlyHistoryData) {
    soundSource.value = data.cdr_data.callRecordURL || ''
    recordingDuration.value = calcRecordingDuration(data)
}
export async function fetchSoundData () {
    if (soundSource.value && !hasRecordDecryptionData.value) {
        const res = await fetch(soundSource.value)
        if (res.status === STATUS_FORBIDDEN) {
            useNotifyService.add({
                type: 'error',
                title: 'Forbidden!',
                message: 'You have no permission for this file!',
                group: 'top-right'
            })
            return
        }
        const blob = await res.blob()

        soundData.value = URL.createObjectURL(blob)
    }

    if (recordDecryptionData.value && soundSource.value) {
        const res = await decryptor.audio.decryptByData(
            soundSource.value,
            recordDecryptionData.value
        )

        const audio = res?.getWav()

        if (!audio) {
            useNotifyService.add({
                type: 'error',
                title: 'Time Out Error!',
                message: 'You have no permission for this file! Should to reload this page.',
                group: 'top-right'
            })
            return
        }

        return soundData.value = URL.createObjectURL(audio)
    }
}
