<template>
    <VcTag
        :label="labelCapitalized"
        class="subtitle-tag"
        borderless
        :style="{ '--color': color }"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'

/* Props */
const props = defineProps<{
    label: string
    color: string
}>()

/* Computed */
const labelCapitalized = computed(() => {
    return props.label.charAt(0).toUpperCase() + props.label.slice(1)
})
</script>

<style scoped lang="scss">
.subtitle-tag {
    @apply font-medium;
    // Should use --color var for bg but make it lighter because text color is also the same
    background-color: color-mix(in srgb, var(--color) 5%, transparent 0%);

    :deep(.vc-tag--label) {
        @apply text-sm;
        color: var(--color);
    }
}
</style>
