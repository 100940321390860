import { computed } from 'vue'
import { MessageSpeakerType, TKeyWordsSpeakerMapped, TMessageItem, TMessageWithEmotionItem } from '@/types/types'
import {
    Participant,
    Sentence,
    SentenceLikeWithStrength,
    TKeyWordsSpeaker
} from '@/types/api'
import { AGENT_SPEAKER, CALLER_SPEAKER } from '@/enums/aiTab.enum.ts'
import { closestMessage } from '@/helpers/apiData.helper.ts'
import { getTranslateWithDefault } from '@/helpers/translate.ts'
import { AIDataRef, getSentences, ParticipantsRef, TranscriptionDataRef } from '@/composables/useAIData.ts'
import { getDefaultEmotionColor } from '@/composables/useColorsComposable.ts'

export type ParticipantsDataExtended = Participant & {
    messages: Array<TMessageWithEmotionItem>
    emotions: Array<Sentence>
    total_talking_time: number
}

export type ParticipantsWithEmotions = {
    client: ParticipantsDataExtended
    agent: ParticipantsDataExtended
}

export type EmotionOccurrence = {
    emotion: string
    occurrences: number
}

export type EmotionWithStrength = {
    emotion: string
    strength: number
}
export interface KeyWordGroup {
    count?: number;
    occurrences?: number;
    words: Array<string>;
}
export interface ActivitySentence extends Sentence {
    message?: TMessageItem
    messageSpeaker: MessageSpeakerType
    speakerName: string
}

export const sentences = computed(() => {
    const sentences = getSentences()
    return (sentences || []).filter(i => !!i.emotion)
})
export const activitySentences = computed<Array<ActivitySentence>>(() => {
    return sentences.value
        .filter(item => item.emotion_direction !== 0)
        .reduce<Array<ActivitySentence>>(
            (acc, item) => {
                const message = TranscriptionDataRef.value?.find((i) => i.sentence_id === item.sentence_id)

                if (message) {
                    acc.push({
                        ...item,
                        message,
                        messageSpeaker: message.speaker,
                        speakerName: message.speaker === CALLER_SPEAKER ? ParticipantsRef.value?.client?.name || 'No Name'
                            : ParticipantsRef.value?.agent?.name || 'No Name'
                    })
                }

                return acc
            },
            []
        )
})
export const firstMessage = computed(() => {
    if (!transcriptMessagesWithEmotions.value) {
        return
    }

    return { ...transcriptMessagesWithEmotions.value[0] }
})
export const transcriptMessagesWithEmotions = computed<Array<TMessageWithEmotionItem>>(() => {
    // console.log({ transcriptionData: transcriptionData.value }, sentences.value)
    return TranscriptionDataRef.value?.reduce<Array<TMessageWithEmotionItem>>(
        (acc, item) => {
            const emotion = sentences.value.find(sentence => sentence.sentence_id === item.sentence_id)

            acc.push({
                ...item,
                emotion: emotion ? emotion : {
                    sentence_id: item.sentence_id,
                    emotion: '',
                    emotion_direction: 0,
                    confidence_emotion: 0,
                    intensity_emotion: 0,
                    personality_trait: '',
                    confidence_trait: 0
                }
            })

            return acc
        },
        []
    ) || []
})
export const aiInsights = computed(() => {
    return AIDataRef.value?.insights
})
export const keywordsMapped = computed(() => {
    const keywordsSpeaker0 = aiInsights.value?.keywords_speaker0 || []
    const keywordsSpeaker1 = aiInsights.value?.keywords_speaker1 || []

    return {
        keywordsSpeaker0: mapKeywords(keywordsSpeaker0),
        keywordsSpeaker1: mapKeywords(keywordsSpeaker1)
    }
})
export function mapKeywords (keywords: Array<TKeyWordsSpeaker>): Array<TKeyWordsSpeakerMapped> {
    return keywords.map((item) => {
        return {
            name: item.word ?? item.keyword ?? '',
            value: item.count ?? item.occurrences ?? 0
        }
    })
}
export const callersData = computed<ParticipantsWithEmotions | undefined>(() => {
    // console.log(aiInsights.value?.participants, isApiOnlyData.value)
    if (!aiInsights.value?.participants) {
        return undefined
    }
    const participantsClient = aiInsights.value.participants.client ?? aiInsights.value.participants.caller ?? {}
    const participantsAgent = aiInsights.value.participants.agent ?? aiInsights.value.participants.callee ?? {}

    return transcriptMessagesWithEmotions.value.reduce<ParticipantsWithEmotions>(
        (acc, item) => {
            if (item.speaker === CALLER_SPEAKER) {
                if (item.emotion.emotion !== '') {
                    acc.client.emotions.push(item.emotion)
                }

                acc.client.messages.push(item)
                acc.client.total_talking_time += item.endTime - item.startTime
            } else if (item.speaker === AGENT_SPEAKER) {
                if (item.emotion.emotion !== '') {
                    acc.agent.emotions.push(item.emotion)
                }

                acc.agent.messages.push(item)

                acc.agent.total_talking_time += item.endTime - item.startTime
            }

            return acc
        },
        {
            client: {
                ...participantsClient,
                total_talking_time: 0,
                messages: [],
                emotions: []
            },
            agent: {
                ...participantsAgent,
                total_talking_time: 0,
                messages: [],
                emotions: []
            }
        }
    )
})
export const totalCallDuration = computed(() => {
    const agentTotalTime = callersData.value?.agent?.total_talking_time ?? 0
    const clientTotalTime = callersData.value?.client?.total_talking_time ?? 0

    return agentTotalTime + clientTotalTime
})
export function getCallerDataByMessage (message?: TMessageItem) {
    const _key = message?.speaker
    //console.log(_key, callersData.value)
    if (_key === AGENT_SPEAKER) {
        return callersData.value?.agent?.name || 'Unknown'
    } else {
        return callersData.value?.client?.name || 'Unknown'
    }
}
export function getMessageByTime (time: number) {
    if (!time) {
        return firstMessage.value
    }
    return closestMessage(time, transcriptMessagesWithEmotions.value)
}
export function getEmotionValue (emotion: Sentence) {
    return emotion.emotion_direction * emotion.confidence_emotion * emotion.intensity_emotion
}
export function getEmotionAbsoluteValue (emotion: Sentence, decimals = 2) {
    return +((emotion.confidence_emotion * emotion.intensity_emotion).toFixed(decimals))
}
export function getEmotionsOccurrences (emotions: Array<Sentence>): Array<EmotionOccurrence> {
    return emotions.reduce<Array<EmotionOccurrence>>(
        (acc, emotion) => {
            const _emotion = acc.find(item => item.emotion === emotion.emotion)

            if (_emotion) {
                _emotion.occurrences++
            } else {
                acc.push({
                    emotion: emotion.emotion,
                    occurrences: 1
                })
            }

            return acc
        },
        []
    )
}
export function getEmotionStrength (emotions: Array<Sentence>): Array<EmotionWithStrength> {
    return emotions.reduce<Array<EmotionWithStrength>>(
        (acc, emotion) => {
            const _emotion = acc.find(item => item.emotion === emotion.emotion)

            if (_emotion) {
                _emotion.strength += Math.abs(getEmotionValue(emotion))
            } else {
                acc.push({
                    emotion: emotion.emotion,
                    strength: Math.abs(getEmotionValue(emotion))
                })
            }

            return acc
        },
        []
    )
}
export function getEmotionsWithStrength <T extends Sentence> (emotions: Array<T>): Array<SentenceLikeWithStrength<T>> {
    return emotions.reduce<Array<SentenceLikeWithStrength<T>>>(
        (acc, emotion) => {
            const _emotion = acc.find(item => item.emotion === emotion.emotion)

            if (_emotion) {
                _emotion.strength += Math.abs(getEmotionValue(emotion))
            } else {
                acc.push({
                    ...emotion,
                    strength: Math.abs(getEmotionValue(emotion))
                })
            }

            return acc
        },
        []
    )
}
export function getEmotionColor (emotion: string) {
    const defaultColor = getTranslateWithDefault('callHistoryView.emotionColors.default', getDefaultEmotionColor())

    return getTranslateWithDefault(`callHistoryView.emotionColors.${emotion}`, defaultColor)
}

export function getSortedGroupedList (list: Array<TKeyWordsSpeakerMapped>, maxGroups = 5) {
    return list.sort((a, b) => b.value - a.value)
        .reduce((groups, item) => {
            const group = groups.find(i => (i.count ?? i.occurrences) === item.value)
            if (group) {
                group.words.push(item.name)
            } else {
                if (groups.length < maxGroups) {
                    groups.push({
                        count: item.value,
                        words: [ item.name ]
                    })
                } else {
                    groups[groups.length - 1].words.push(item.name)
                }
            }
            return groups
        }, [] as Array<KeyWordGroup>)
}
