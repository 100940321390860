<template>
    <VChart
        ref="chartRef"
        class="emotions-bar-chart"
        :option="barChartOptions"
        autoresize
        :style="{ height: chartHeight }"
        @finished="onFinished"
    />
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { INIT_OPTIONS_KEY, TChartRender, THEME_KEY } from '@/composables/useEChartComponents.ts'
import VChart from 'vue-echarts'
import { AgentColor, ClientColor, DEFAULT_CHART_THEME } from '@/composables/useColorsComposable.ts'
import { activitySentences, getEmotionValue } from '@/composables/useAiTabData.ts'
import { AGENT_SPEAKER, CALLER_SPEAKER } from '@/enums/aiTab.enum.ts'
import { agentName, clientName } from '@/composables/useAIData.ts'
import { ObjectAnyType } from '@/types/types'
import { getSvgBase64Icon } from '@voicenter-team/emotion-icons'

const { t } = useI18n()

type barSeriesDataType = {
    agent: Record<string, number>
    client: Record<string, number>
}

const MIN_HEIGHT_GROUP = 40
const MIN_HEIGHT_CHART = 360
const USER_KEYS: Array<keyof barSeriesDataType> = [ 'agent', 'client' ] // for correct data sides
const COLORS = computed(() => {
    return {
        agent: AgentColor.value,
        client: ClientColor.value
    }
})

/* Props */
const props = defineProps<{
    theme?: string
    renderer?: TChartRender
    printMode?: boolean
}>()
/* Emits */
const emit = defineEmits([ 'finished' ])

/* Chart Global Settings */
const initOptions = computed(() => ({
    renderer: props.renderer || 'svg'
}))
provide(THEME_KEY, props.theme || DEFAULT_CHART_THEME)
provide(INIT_OPTIONS_KEY, initOptions)

/* Data */

/* Computed */
const chartHeight = computed(() => {
    const height = emotionsAllSet.value.length * MIN_HEIGHT_GROUP
    const finalHeight = Math.max(height, MIN_HEIGHT_CHART)
    return `${finalHeight}px`
})
const seriesNames = computed(() => {
    return {
        agent: (agentName.value || t('callHistoryView.agent')),
        client: (clientName.value || t('callHistoryView.client'))
    }
})
const sentencesDataMapped = computed(() => {
    return [ ...activitySentences.value ].reduce<barSeriesDataType>((acc, currentSentence) => {
        if (currentSentence.message) {
            const rate = getEmotionValue(currentSentence).toFixed(2)
            const emotion = currentSentence.emotion
            if (currentSentence.messageSpeaker === AGENT_SPEAKER) {
                if (!acc.agent[emotion]) {
                    acc.agent[emotion] = 0
                }
                acc.agent[emotion] += +rate
            } else if (currentSentence.messageSpeaker === CALLER_SPEAKER) {
                if (!acc.client[emotion]) {
                    acc.client[emotion] = 0
                }
                acc.client[emotion] += +rate
            }
        }

        return acc
    }, {
        agent: {},
        client: {}
    })
})
const emotionsAllSet  = computed(() => {
    const sentences = sentencesDataMapped.value
    const agentEmotion = Object.keys(sentences['agent']) || []
    const clientEmotion = Object.keys(sentences['client']) || []

    return [ ...new Set([ ...agentEmotion, ...clientEmotion ]) ]
})
const barChartOptions = computed(() => {
    const sentences = sentencesDataMapped.value
    const legendData = []
    const agentEmotion = Object.keys(sentences['agent']) || []
    const clientEmotion = Object.keys(sentences['client']) || []
    const hasAgent = !!agentEmotion.length
    const hasClient = !!clientEmotion.length

    if (hasAgent) {
        legendData.push(seriesNames.value.agent)
    }
    if (hasClient) {
        legendData.push(seriesNames.value.client)
    }
    const series: Array<ObjectAnyType> = []
    const setEmotions = [ ...emotionsAllSet.value ]

    USER_KEYS.forEach((key) => {
        const _name = seriesNames.value[key]
        const userEmotions = sentences[key]
        series.push({
            type: 'bar',
            name: _name,
            label: {
                show: true,
                position: 'inside'
            },
            emphasis: {
                focus: 'series'
            },
            data: setEmotions.map(emotionName => userEmotions[emotionName] !== undefined ? userEmotions[emotionName].toFixed(2) : '-'),
            color: COLORS.value[key],
            barGap: 0
        })
    })

    return {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {
            show: true,
            top: 0,
            data: [ ...legendData ].reverse()
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'value',
            }
        ],
        yAxis: [
            {
                type: 'category',
                axisTick: {
                    show: false
                },
                data: setEmotions,
                axisLabel: {
                    formatter: (value: string) => {
                        // Use rich text style and map icons
                        return window.innerWidth > 960 ? `{${value}|} {text|${value}}` : `{${value}|}`
                    },
                    rich: { ...generateRichStyles(setEmotions) }
                }
            }
        ],
        series
    }
})

/* Methods */
function generateRichStyles (setEmotions: Array<string>) {
    const rich: Record<string, ObjectAnyType> = {}
    setEmotions.forEach((label) => {
        rich[label] = {
            height: 16,
            width: 16,
            align: 'center',
            backgroundColor: {
                image: `${getSvgBase64Icon(label.toLowerCase())}`,
            },
        }
    })
    rich.text = {
        fontSize: props.printMode ? 10 : 14,
        color: 'var(--default-text, black)',
        padding: [ 0, 0, 0, 8 ], // Padding between icon and text
    }
    return rich
}
function onFinished () {
    emit('finished')
}
</script>

