<template>
    <div class="call-history-info-card overflow-hidden">
        <div class="uppercase font-semibold text-placeholders text-xs">
            {{ content.title }}
        </div>
        <div
            class="text-default-text font-medium pt-3 lg:pt-4 flex items-center gap-1.5 whitespace-break-spaces"
        >
            <span
                v-if="content.link"
                class="cursor-pointer hover:text-active-elements inline-flex items-center gap-1.5"
            >
                <span>{{ content.linkName }}</span>
                <i class="vc-icon-open icon-lg" />
            </span>
            <img
                v-else-if="content.image"
                :src="content.image"
                alt="Some Img"
                class="object-contain w-5 h-auto"
            >
            <span :class="content.classes">{{ content.content || t('callHistoryView.empty') }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TContentItem } from '@/types/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface IProps {
    content: TContentItem
}

defineProps<IProps>()
</script>
