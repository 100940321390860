import { defineCustomElement } from 'vue'
import CallHistoryWidget from '@/widget/CallHistoryWidget.ce.vue'
import UI from '@voicenter-team/voicenter-ui-plus'
import i18n from '@/plugins/i18n.ts'
import notifyService from '@/plugins/notifyPlugin.ts'
import styles1 from '@voicenter-team/voicenter-ui-plus/library/style.css?inline'
import styles2 from '@/assets/scss/index.scss?inline'
import echartsPlugin from '@/plugins/echartsPlugin.ts'
// import '@/assets/fonts/font-rubik.css'

const widget = defineCustomElement(CallHistoryWidget, {
    configureApp (app) {
        app.use(UI, {
            entityComponentsEnabled: false,
            themeConfig: {
                type: 'none'
            },
            injectIconFont: true
        })
        app.use(i18n)
        app.use(notifyService)
        app.use(echartsPlugin)
    },
    styles: [ styles1, styles2 ],

})

customElements.define('call-history-widget', widget)
