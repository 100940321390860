<template>
    <div
        class="call-history-ai-sound rounded-md border border-ui-lines"
        dir="ltr"
    >
        <VcSoundPlayerWrapper
            v-if="isRecording"
            ref="soundPlayerWrapperRef"
            :src="soundData"
            :options="playerOptions"
            :skip-seconds="skipNumber"
            :default-volume="defaultValue"
            show-hover
            @ready="onReady"
            @start="onSoundPlayerStart"
            @stop="onSoundPlayerStop"
            @update:time="onCurrentPlayerTimeUpdate"
        >
            <div class="px-3 py-2">
                <div class="flex items-center justify-between text-xs leading-none mb-2">
                    <VcSoundCurrentTime />
                    <VcSoundDuration />
                </div>
                <div class="">
                    <VcSoundPlayerProgress
                        class="h-5"
                        show-by-init
                    />
                </div>
            </div>
            <div class="p-2">
                <div class="w-full flex items-center justify-between">
                    <div class="flex items-center gap-x-3">
                        <VcPlayerPlayStopButton
                            play-icon="vc-lc-circle-play"
                            stop-icon="vc-lc-circle-stop"
                            pause-icon="vc-lc-circle-pause"
                            icon-class="icon-xl"
                            class="gap-x-2"
                        />
                        <VcPlayerSkipButtons
                            icon-back="vc-lc-fast-forward rotate-180 pt-0.5"
                            icon-forward="vc-lc-fast-forward"
                            icon-class="icon-xl"
                            class="flex items-center"
                        />
                        <VcSoundVolume
                            v-if="soundData"
                            inline-mode
                            icon-class="icon-xl"
                            class="w-32"
                        />
                    </div>
                    <div class="flex-center">
                        <VcPlayerPlaybackRatePopup
                            :rates="[1,2,3,4,5]"
                        />
                    </div>
                </div>
            </div>
        </VcSoundPlayerWrapper>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref } from 'vue'
import {
    VcPlayerPlaybackRatePopup,
    VcPlayerPlayStopButton,
    VcPlayerSkipButtons,
    VcSoundCurrentTime,
    VcSoundDuration,
    VcSoundPlayerProgress,
    VcSoundPlayerWrapper,
    VcSoundVolume
} from '@voicenter-team/voicenter-ui-plus'
import { WaveSurferOptions } from 'wavesurfer.js'
import {
    isRecording,
    recordingDuration,
    soundData,
    onCurrentPlayerTimeUpdate,
    onSoundPlayerWrapperInitialized,
    clearSoundPlayerWrapperRef,
    onSoundPlayerStop,
    onSoundPlayerStart,
    SoundPlayerWrapperRef,
} from '@/composables/useCallRecording.ts'
import { SoundProgressColor, SoundWavesColor } from '@/composables/useColorsComposable.ts'

const skipNumber = 15
const defaultValue = 50

/* Data */
const soundPlayerWrapperRef = ref<typeof VcSoundPlayerWrapper>()

/* Player Options */
const playerOptions = computed<WaveSurferOptions>(() => {
    return {
        container: '',
        height: 20,
        waveColor: SoundWavesColor.value,
        progressColor: SoundProgressColor.value,
        barWidth: 2,
        cursorColor: 'var(--progress-line-not-done)'
    }
})

function onReady () {
    soundPlayerWrapperRef.value?.setDurationTime(recordingDuration.value)
}

onSoundPlayerWrapperInitialized(soundPlayerWrapperRef as SoundPlayerWrapperRef)

onBeforeUnmount(clearSoundPlayerWrapperRef)

// onMounted(() => {
//     setTimeout(() => {
//         mounted.value = true
//     }, 1000)
// })
</script>
