<template>
    <div class="chart-activity-card">
        <div
            v-if="currentMessage"
            class="chart-activity-card__content"
        >
            <div class="chart-activity-card__top">
                <div class="flex items-center">
                    <div
                        v-if="currentMessage.emotion?.emotion"
                        class="flex-center flex-shrink-0 mr-3"
                    >
                        <BaseEmojiGifIcon :emotion="currentMessage.emotion?.emotion" />
                    </div>

                    <div class="text-base font-medium">
                        {{ speakerName }}
                    </div>
                </div>

                <div class="chart-activity-card__activities flex-shrink-0">
                    <div class="flex flex-col sm:flex-row w-full sm:w-auto gap-2">
                        <SubtitleTag
                            v-if="currentMessage?.emotion?.personality_trait"
                            :color="tagColor"
                            :label="getTranslate(`callHistoryView.ai.personalityTrait.${currentMessage.emotion.personality_trait?.toLowerCase()}`, currentMessage.emotion.personality_trait)"
                        />
                        <SubtitleTag
                            v-if="currentMessage.emotion?.emotion"
                            :color="emotionColor(currentMessage.emotion.emotion)"
                            :label="getTranslate(`callHistoryView.ai.emotions.${currentMessage.emotion.emotion?.toLowerCase()}`, currentMessage.emotion.emotion)"
                        />
                    </div>
                </div>
            </div>

            <div class="chart-activity-card__bottom">
                <div
                    class="chart-activity-card__message"
                >
                    <span
                        class="chart-activity-card__message-text"
                        :style="messageStyle"
                    >
                        {{ currentMessage.text }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TMessageWithEmotionItem } from '@/types/types'
import { computed, nextTick, ref, watch } from 'vue'
import { getCallerDataByMessage, getEmotionColor, getMessageByTime } from '@/composables/useAiTabData.ts'
import BaseEmojiGifIcon from '@/components/Icons/BaseEmojiGifIcon.vue'
import { getTranslateWithDefault } from '@/helpers/translate.ts'
import { getCurrentPlayerTime } from '@/composables/useCallRecording.ts'
import SubtitleTag from '@/components/Common/SubtitleTag.vue'
import { AGENT_SPEAKER } from '@/enums/aiTab.enum.ts'
import { AGENT_COLOR, CALLER_COLOR } from '@/enums/colors.enum.ts'

/* Data */
const currentMessage = ref<TMessageWithEmotionItem | undefined>(undefined)

/* Computed */
const speakerName = computed(() => {
    return getCallerDataByMessage(currentMessage.value)
})
const tagColor = computed(() => {
    return currentMessage.value?.speaker === AGENT_SPEAKER ? AGENT_COLOR : CALLER_COLOR
})
const fontSize = computed(() => {
    const textLength = currentMessage.value?.text.length || 0
    const maxFontSize = 20 // in px
    const minFontSize = 14 // in px
    const minLength = 40   // character count where font size starts decreasing
    const maxLength = 200  // character count where font size reaches minFontSize

    if (textLength <= minLength) {
        return maxFontSize
    } else if (textLength >= maxLength) {
        return minFontSize
    } else {
        return maxFontSize - ((textLength - minLength) * (maxFontSize - minFontSize)) / (maxLength - minLength)
    }
})
const isEmotionShown = computed(() => {
    return !!currentMessage.value?.emotion?.emotion
})
const maxLines = computed(() => {
    // Show fewer lines if emotion is displayed
    return isEmotionShown.value ? 3 : 4
})
const messageStyle = computed(() => {
    return {
        fontSize: fontSize.value + 'px',
        lineHeight: fontSize.value + 2 + 'px',
        WebkitLineClamp: maxLines.value.toString(),
    }
})

/* Methods */
function getTranslate (str: string, defaultVal: string) {
    return getTranslateWithDefault(str, defaultVal)
}
function emotionColor (emotion: string = '') {
    return getEmotionColor(emotion)
}
function setMessageByTime (time: number) {
    currentMessage.value = getMessageByTime(time) || currentMessage.value
}
function initData () {
    nextTick(() => {
        currentMessage.value = getMessageByTime(0)
    })
}

initData()

/* Watcher */
watch(
    getCurrentPlayerTime,
    (time) => {
        setMessageByTime(time)
        // filterPolarChartMessages(time)
    }
)
</script>

<style lang="scss">
.chart-activity-card {
    @apply px-6 py-4 h-[144px] flex items-stretch;

    .vc-card-content {
        @apply h-auto;
    }
    &__content {
        @apply w-full;
    //    @apply grid gap-4 grid-cols-1 sm:grid-cols-[auto_1fr_auto] h-full;
    }

    &__top {
        @apply flex justify-between mb-4;
    }

    &__bottom {
        @apply flex;
    }

    &__message {
        @apply rounded-xl rounded-tl-none px-2 py-1 leading-none break-all;
        color: var(--message-agent-text-color, #2A2C36);
        background-color: var(--message-agent-bg-color, #EDF4FF);

        &-text {
            overflow-wrap: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-word;
        }
    }

    &__activities {
        @apply flex items-start;
    }
    .personality-trait {
        @apply text-2xl max-w-full sm:max-w-72;
    }
}
</style>
