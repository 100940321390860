<template>
    <div class="simple-loading-container">
        <div class="loading-text">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.simple-loading-container {
    width: 100%;
    max-width: 520px;
    text-align: center;
    color: var(--primary, #D34C4C);
    position: relative;
    margin: 0 32px;
    padding: 4px 8px;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: var(--primary, #D34C4C);
        bottom: 0;
        left: 0;
        border-radius: 10px;
        animation: movingLine 2.4s infinite ease-in-out;
    }
}

@keyframes movingLine {
    0% {
        opacity: 0;
        width: 0;
        filter: hue-rotate(120deg);
    }

    33.3%, 66% {
        opacity: 0.8;
        width: 100%;
        filter: hue-rotate(0deg);
    }

    85% {
        width: 0;
        left: initial;
        right: 0;
        opacity: 1;
        filter: hue-rotate(-120deg);
    }

    100% {
        opacity: 0;
        width: 0;
        filter: hue-rotate(0deg);
    }
}

.loading-text {
    font-size: 4vw;
    line-height: 1;
    letter-spacing: 8px;
    margin-bottom: 20px;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
    span {
        animation: moveLetters 2.4s infinite ease-in-out;
        transform: translatex(0);
        position: relative;
        display: inline-block;
        opacity: 0;
        text-shadow:
            0 1px 0 #ccc,
            0 2px 0 #c9c9c9,
            0 6px 1px rgba(0,0,0,.1)
    ;
    }
}

@for $i from 1 through 7 {
    .loading-text span:nth-child(#{$i}) {
        animation-delay: $i * 0.1s;
    }
}

@keyframes moveLetters {
    0% {
        transform: translateX(-15vw);
        opacity: 0;
        filter: hue-rotate(-120deg);
    }

    33.3%, 66% {
        transform: translateX(0);
        opacity: 1;
        filter: hue-rotate(0deg);
    }

    100% {
        transform: translateX(15vw);
        opacity: 0;
        filter: hue-rotate(-120deg);
    }
}
</style>
