<template>
    <div class="w-full emotions-polar-chart-block">
        <VChart
            ref="chartRef"
            class="emotions-polar-chart"
            :option="polarChartOptions"
            autoresize
            :style="{ height: '240px' }"
            @click="onClick"
            @finished="onFinished"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import VChart from 'vue-echarts'
import { useI18n } from 'vue-i18n'
import { activitySentences, getEmotionAbsoluteValue, getEmotionsWithStrength } from '@/composables/useAiTabData.ts'
import {
    USER_AGENT_TYPE,
    USER_TYPE_TO_SPEAKER_TYPE,
    UserTypes
} from '@/enums/aiTab.enum.ts'
import { getSvgBase64Icon } from '@voicenter-team/emotion-icons'
import { agentName, clientName } from '@/composables/useAIData.ts'
import { AgentColor, ClientColor, DEFAULT_CHART_THEME } from '@/composables/useColorsComposable.ts'
import { INIT_OPTIONS_KEY, TChartRender, THEME_KEY } from '@/composables/useEChartComponents.ts'
import { currentPlayerTimeDebounced } from '@/composables/useCallRecording.ts'

const innerWidth = window.innerWidth
const iconSize = innerWidth > 960 ? 18 : 14

const { t } = useI18n()

/* Props */
const props = withDefaults(
    defineProps<{
        theme?: string
        renderer?: TChartRender
        emotionsToShow?: number
        height?: string
        user: UserTypes
    }>(),
    {
        emotionsToShow: 10,
        renderer: 'svg',
        theme: DEFAULT_CHART_THEME,
        height: '320px'
    }
)

/* Emits */
const emit = defineEmits<{
    (e: 'finished'): void,
    (e: 'emotion:click', payload: string): void
}>()

/* Chart Global Settings */
const initOptions = computed(() => ({
    renderer: props.renderer || 'svg'
}))
provide(THEME_KEY, props.theme || DEFAULT_CHART_THEME)
provide(INIT_OPTIONS_KEY, initOptions)

/* Computed */
const color = computed(() => {
    return props.user === USER_AGENT_TYPE ? AgentColor.value : ClientColor.value
})
const seriesName = computed(() => {
    return props.user === USER_AGENT_TYPE
        ? agentName.value || t('callHistoryView.agent')
        : clientName.value || t('callHistoryView.client')
})
const userActivitySentences = computed(() => {
    return activitySentences.value.filter(i => i.messageSpeaker === USER_TYPE_TO_SPEAKER_TYPE[props.user])
})
const filteredSentencesByTime = computed(() => {
    const sentencesFiltered = userActivitySentences.value
        .filter(i => i.message?.startTime && i.message?.startTime <= currentPlayerTimeDebounced.value)

    return sentencesFiltered.length
        ? sentencesFiltered
        : userActivitySentences.value
})
const emotionsWithStrength = computed(() => {
    return getEmotionsWithStrength(filteredSentencesByTime.value).sort((a, b) => b.strength - a.strength).splice(0, props.emotionsToShow)
})
const sortedSentences = computed(() => {
    return [ ...emotionsWithStrength.value ]
        .sort((a, b) => (b.emotion_direction - a.emotion_direction))
})
const sentencesDataMapped = computed(() => {
    return sortedSentences.value.reduce<Record<string, number>>(
        (acc, currentSentence) => {
            if (currentSentence.message) {
                const rate = getEmotionAbsoluteValue(currentSentence, 3)
                const emotion = currentSentence.emotion

                if (!acc[emotion]) {
                    acc[emotion] = 0
                }

                acc[emotion] += Math.abs(rate)
            }
            return acc
        },
        {}
    )
})
const polarChartOptions = computed(() => {
    const setEmotions = [ ...new Set(Object.keys(sentencesDataMapped.value)) ]

    return {
        tooltip: {
            trigger: 'item',
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // White background with slight transparency
            borderColor: 'var(--ui-lines)',
            borderWidth: 1,
            textStyle: {
                color: 'var(--default-text, black)',
                fontSize: 12,
            },
            padding: [ 8, 12 ],
            formatter: function (params: Record<string, string>) {
                return `
                    <div style="text-align: left;">
                      <span>${t('callHistoryView.general.emotion')}: ${params.name}</span><br/>
                      <span>${t('callHistoryView.general.weight')}: ${params.value}</span>
                    </div>`
            },
        },
        legend: {
            show: false
        },
        angleAxis: {
            axisLine: {
                show: true,
                lineStyle: {
                    color: 'var(--ui-lines)',
                    width: 1,
                    type: 'solid'
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                formatter (label: string) {
                    return `{icon|} {name|${toCapitalize(label)}}`
                },
                color: 'var(--default-text, black)',
                interval: 0,
                showMaxLabel: null,
                rich: {
                    name: {
                        color: 'var(--default-text, black)',
                        fontSize: 8,
                        align: 'center',
                        verticalAlign: 'center'
                    }
                }
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: 'var(--ui-lines)',
                    width: 1,
                    type: 'solid'
                }
            },
            type: 'category',
            data: getAngleAxisDataMapped(setEmotions),
        },
        radiusAxis: {
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
            splitLine: { show: false }
        },
        polar: { center: [ '52%', '50%' ] },
        series: [
            {
                type: 'bar',
                name: seriesName.value,
                data: setEmotions.map(emotionName => sentencesDataMapped.value[emotionName] ?? '-'),
                stack: 'a',
                coordinateSystem: 'polar',
                color: color.value,
                emphasis: {
                    itemStyle: {
                        borderColor: '#FFF',
                        borderWidth: 3,
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.3)',
                    },
                },
                barCategoryGap: '1px'
            }
        ]
    }
})

/* Methods */
function onClick ({ name }: { name: string }) {
    emit('emotion:click', name)
}
function getAngleAxisDataMapped (data: Array<string>) {
    return [ ...data ].map(kItem => ({
        value: kItem,
        textStyle: {
            rich: {
                icon: {
                    height: iconSize,
                    width: iconSize,
                    align: 'center',
                    backgroundColor: {
                        image: `${getSvgBase64Icon(kItem.toLowerCase())}`
                    }
                }
            }
        }
    }))
}
function toCapitalize (label: string) {
    return label.charAt(0).toUpperCase() + label.slice(1)
}
function onFinished () {
    emit('finished')
}
</script>
