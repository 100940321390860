<template>
    <div class="w-full call-rtcp-chart">
        <v-chart
            ref="chartRef"
            class="emotional-lines-chart__chart"
            :option="chartOptions"
            autoresize
            :style="{ height: height || DEFAULT_HEIGHT }"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue'
import { DEFAULT_CHART_THEME, getRTCPColors } from '@/composables/useColorsComposable.ts'
import { INIT_OPTIONS_KEY, TChartRender, TEChartsOption, THEME_KEY } from '@/composables/useEChartComponents.ts'

const DEFAULT_HEIGHT = '400px'

interface ChartPropsData {
    avgMos: Array<number[]>
    avgJitter: Array<number[]>
    avgPctLoss: Array<number[]>
}

/* Props */
const props = defineProps<{
    theme?: string
    renderer?: TChartRender
    height?: string
    data?: ChartPropsData
}>()

/* Chart Global Settings */
const initOptions = computed(() => ({
    renderer: props.renderer || 'svg'
}))
provide(THEME_KEY, props.theme || DEFAULT_CHART_THEME)
provide(INIT_OPTIONS_KEY, initOptions)

/* Options */
const chartOptions = ref<TEChartsOption>({
    color: [ ...getRTCPColors() ],
    tooltip: {
        show: true,
        trigger: 'axis',
    },
    toolbox: {
        feature: {
            saveAsImage: {}
        }
    },
    legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        selectedMode: 'multiple',

    },
    grid: {
        left: '30px',
        right: '30px',
        bottom: '4px',
        top: '28px',
        containLabel: true
    },
    yAxis: {
        type: 'value',
        show: true,
        axisLabel: { show: true },
        splitLine: {
            lineStyle: { type: 'dashed' }
        },
        silent: true
    },
    xAxis: {
        type: 'time',
        axisLine: {
            onZero: true,
            lineStyle: {
                type: 'solid',
                // color: 'var(--ui-lines, #BFBFBF)',
                width: 1,
            }
        }
    },
    series: [
        {
            name: 'AVG Mos',
            type: 'line',
            data: props.data?.avgMos || [],
            smooth: true,
            symbol: 'rect',
            // symbolSize: 8,
            selectedMode: 'single',
            emphasis: {
                focus: 'series',
                scale: 1.2,
                // lineStyle: {
                //     width: 5
                // }
            }
        },
        {
            name: 'Jitter',
            type: 'line',
            symbol: 'circle',
            // symbolSize: 8,
            data: props.data?.avgJitter || [],
            smooth: true,
            selectedMode: 'single',
            emphasis: {
                focus: 'series',
                scale: 1.2,
                // lineStyle: {
                //     width: 5
                // }
            }
        },
        {
            name: 'Packet Loss',
            type: 'line',
            symbol: 'diamond',
            // symbolSize: 8,
            data: props.data?.avgPctLoss || [],
            smooth: true,
            selectedMode: 'single',
            emphasis: {
                focus: 'series',
                scale: 1.2,
                // lineStyle: {
                //     width: 5
                // }
            }
        },
    ]
})
</script>
