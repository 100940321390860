<template>
    <div class="call-history-ai-tab">
        <div v-if="!hasAiData">
            <div class="w-full rounded bg-inactive-elements-bg--focus flex-center py-5">
                <div class="font-medium text-base">
                    {{ t('callHistoryView.noData') }}
                </div>
            </div>
        </div>
        <div
            v-else
            class="ai-tab__content"
        >
            <div class="ai-tab__player">
                <CallHistoryAiSound class="border-none" />
            </div>
            <div class="ai-tab__subtitle">
                <SubtitleBlock />
            </div>
            <div class="ai-tab__chart">
                <CallEmotionAnalysisBlock ref="callEmotionAnalysisBlockRef" />
            </div>
            <div class="ai-tab__main">
                <MainSummaryBlock>
                    {{ aiInsightsSummary }}
                </MainSummaryBlock>
                <EmotionsBarsBlock />
                <MainKeywordsBlock
                    :keywords-speaker0="keywordsMapped.keywordsSpeaker0"
                    :keywords-speaker1="keywordsMapped.keywordsSpeaker1"
                />
            </div>
            <div
                v-if="aiInsights?.questions?.length"
                class="ai-tab__key-points"
            >
                <MainKeyPointsBlock
                    :questions="aiInsights?.questions"
                />
            </div>
            <div class="ai-tab__transcript">
                <CallAiTranscriptionBlock
                    ref="transcriptionBlockRef"
                />
            </div>
            <div
                v-if="isUserAdmin"
                class="ai-tab__footer"
            >
                <div
                    class="uppercase text-sm font-semibold text-placeholders flex items-center"
                >
                    {{ t('callHistoryView.ai.debugInfo') }}
                    <VcPopover
                        :teleported="isTeleported"
                        placement="top"
                        trigger="hover"
                        popover-max-width="420px"
                    >
                        <template #reference>
                            <span class="inline-flex px-1 items-center">
                                <i class="vc-lc-info cursor-pointer" />
                            </span>
                        </template>
                        <div
                            class="p-3 text-xs max-h-[460px] overflow-y-auto"
                            dir="ltr"
                        >
                            <pre>{{ debugAiInfo }}</pre>
                        </div>
                    </VcPopover>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { aiInsightsSummary, debugAiInfo, hasAiData } from '@/composables/useAIData.ts'
import { isUserAdmin } from '@/composables/useJWTPayloadData.ts'
import { aiInsights, keywordsMapped } from '@/composables/useAiTabData.ts'
import { useCallAiTabProvide } from '@/composables/callAiTabProvideInject.ts'
import { TEmotionCallerClickData, TKeyWordsSpeakerMapped } from '@/types/types'
import MainKeywordsBlock from '@/components/Blocks/AI/MainKeywordsBlock.vue'
import MainSummaryBlock from '@/components/Blocks/AI/MainSummaryBlock.vue'
import MainKeyPointsBlock from '@/components/Blocks/AI/MainKeyPointsBlock.vue'
import CallAiTranscriptionBlock from '@/components/Blocks/AI/CallAiTranscriptionBlock.vue'
import CallEmotionAnalysisBlock from '@/components/Blocks/AI/CallEmotionAnalysisBlock.vue'
import SubtitleBlock from '@/components/Blocks/AI/SubtitleBlock.vue'
import { isTeleported } from '@/services/GlobalState.ts'
import CallHistoryAiSound from '@/components/Common/CallHistoryAiSound.vue'
import EmotionsBarsBlock from '@/components/Blocks/AI/EmotionsBarsBlock.vue'

const { t } = useI18n()

/* Data */
const callEmotionAnalysisBlockRef = ref<typeof CallEmotionAnalysisBlock>()
const transcriptionBlockRef = ref<typeof CallAiTranscriptionBlock>()

/* Methods */
function wordClickHandler (data: TKeyWordsSpeakerMapped) {
    transcriptionBlockRef.value?.setSearchData(data.name)
}
function onEmotionClick (data: TEmotionCallerClickData) {
    callEmotionAnalysisBlockRef.value?.highlightEmotionsByUser(data)
}

useCallAiTabProvide({
    wordCloudItemClick: wordClickHandler,
    emotionClickHandler: onEmotionClick
})
</script>

<style lang="scss">
.call-history-ai-tab {
    .ai-tab__content {
        @apply grid rounded-lg border border-ui-lines mb-5 overflow-hidden;
        grid-template-columns: minmax(0, 1fr) min(30%, 630px);
        grid-template-areas:
            "player subtitle"
            "chart transcript"
            "main main"
            "keypoints keypoints"
            "footer footer";
    }
    .ai-tab__summary {
        @apply border-b border-b-ui-lines h-max;
        grid-area: summary;
    }
    .ai-tab__player {
        @apply border-b border-b-ui-lines py-3;
        grid-area: player;
    }
    .ai-tab__subtitle {
        @apply border-b border-b-ui-lines border-l border-l-ui-lines;
        grid-area: subtitle;
    }
    .ai-tab__chart {
        @apply border-b border-b-ui-lines h-max;
        grid-area: chart;
    }
    .ai-tab__transcript {
        @apply border-b border-l border-ui-lines relative pb-3 max-w-full;
        grid-area: transcript;
    }
    .ai-tab__main {
        @apply border-b border-b-ui-lines h-max p-3 lg:p-5 xl:p-8 flex flex-col gap-3 lg:gap-6;
        grid-area: main;
    }
    .ai-tab__key-points {
        @apply py-3 px-3 lg:pt-5 lg:px-5 xl:py-8 xl:px-8;
        grid-area: keypoints;
    }
    .ai-tab__insights {
        @apply px-3 lg:px-5 xl:px-8;
        grid-area: insights;
    }
    .ai-tab__footer {
        @apply border-t border-ui-lines p-3 lg:p-5;
        grid-area: footer;
    }
    .call-history-transcription {
        @apply inset-x-0 top-0 bottom-2;
        position: absolute;
    }
}
@container (max-width: 1264px) {
    .call-history-ai-tab {
        .ai-tab__content {
            grid-template-columns: 1fr;
            grid-template-areas:
                        "summary"
                        "chart"
                        "player"
                        "subtitle"
                        "transcript"
                        "main"
                        "keypoints"
                        "footer";
        }
        .ai-tab__transcript {
            @apply border-l-0;
        }
        .call-history-transcription {
            position: relative;
            max-height: 480px;
        }

        .ai-tab__transcript {
            min-width: 100%;
        }
    }
}
</style>
