export const AGENT_SPEAKER = 'Speaker0' as const
export const CALLER_SPEAKER = 'Speaker1' as const

export const USER_AGENT_TYPE = 0 as const
export const USER_CLIENT_TYPE = 1 as const

export const USER_TYPE_TO_SPEAKER_TYPE = {
    [USER_AGENT_TYPE]: AGENT_SPEAKER,
    [USER_CLIENT_TYPE]: CALLER_SPEAKER
} as const

export type UserTypes = typeof USER_AGENT_TYPE | typeof USER_CLIENT_TYPE
