<template>
    <div class="w-full emotions-bar-chart__wrap">
        <CollapseBlock
            :title="t('callHistoryView.ai.emotionSummaryRadar')"
        >
            <div class="emotions-bar-chart__chart">
                <EmotionsNegativeBarChart />
            </div>
        </CollapseBlock>
    </div>
</template>

<script setup lang="ts">
import CollapseBlock from '@/components/Common/CollapseBlock.vue'
import { useI18n } from 'vue-i18n'
import EmotionsNegativeBarChart from '@/components/Chart/EmotionsNegativeBarChart.vue'

const { t } = useI18n()
</script>

<style scoped>
.emotions-bar-chart__chart {
    direction: ltr;
}
</style>

